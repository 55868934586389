import { ChevronUpDownIcon, PencilIcon } from '@heroicons/react/24/solid';
import { MagnifyingGlassIcon, XCircleIcon } from '@heroicons/react/24/outline';
import {
  CardHeader,
  Typography,
  Button,
  CardBody,
  CardFooter,
  IconButton,
  Tooltip,
  Input,
  Chip,
} from '@material-tailwind/react';
import {
  ChangeEvent,
  KeyboardEvent,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import axios from 'axios';
import { AuthContext } from '../../context';
import timeFormat from '../../utils/timeFormat';
import { PageButtonProps } from '../../types/interface';
import { DollarHistoryType } from '../../types/type';

const TABLE_HEAD = ['아이디', '생성 시간', '유저 아이디', '달러', '타입', ''];
const ORDER_COLUMN = [
  'sortId',
  'sortCreatedAt',
  'sortUserId',
  'sortDollar',
  'sortType',
  '',
];

const DollarHistoryPageButton = ({
  currentPage,
  totalPages,
  goPage,
}: PageButtonProps): ReactElement => {
  const pageButtonIndex = [];
  const maxNum = 10;
  for (let i = currentPage - Math.floor(maxNum / 2); i < currentPage; i += 1) {
    if (i > 0) {
      pageButtonIndex.push(i);
    }
  }

  for (let i = currentPage; i <= totalPages; i += 1) {
    if (pageButtonIndex.length >= maxNum) {
      break;
    }
    pageButtonIndex.push(i);
  }

  return (
    <>
      {pageButtonIndex.map((pageNumber) => (
        <IconButton
          key={pageNumber}
          variant={currentPage === pageNumber ? 'outlined' : 'text'}
          color="blue-gray"
          size="sm"
          onClick={(): void => {
            goPage(pageNumber);
          }}
        >
          {pageNumber}
        </IconButton>
      ))}
    </>
  );
};

export default function DollarHistory(): ReactElement {
  const authToken = useContext(AuthContext);

  const [dollarHistories, setDollarHistorys] = useState<DollarHistoryType[]>(
    []
  );
  const [allDollar, setAllDollar] = useState(0);

  const [pagination, setPagination] = useState<{
    totalPages: number;
    currentPage: number;
  }>({ totalPages: 1, currentPage: 1 });
  const [searchDollarHistory, setSearchDollarHistory] = useState('');
  const [reset, setReset] = useState(false);
  const [order, setOrder] = useState<{ column: string | null; order: string }>({
    column: null,
    order: 'DESC',
  });

  const getAllDollar = async (): Promise<void> =>
    axios
      .get(`${process.env.REACT_APP_API_URL}/dollar-history/dollar`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((res) => {
        const { data } = res;
        setAllDollar(data.dollar);
      });

  const getDollarHistory = async (page = 1, limit = 10): Promise<void> =>
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/dollar-history${
          searchDollarHistory !== '' ? `/search/${searchDollarHistory}` : ''
        }?page=${page}&limit=${limit}${`${
          order.column ? `&${order.column}=${order.order}` : ''
        }`}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      )
      .then((res) => {
        const { items, meta } = res.data;
        if (items && items.length > 0) {
          setDollarHistorys(items);
        } else {
          setDollarHistorys([]);
        }
        if (meta) {
          setPagination({
            totalPages: meta.totalPages,
            currentPage: meta.currentPage,
          });
        }
      })
      .catch(() => {
        setDollarHistorys([]);
        setPagination({
          totalPages: 1,
          currentPage: 1,
        });
      });

  const handleSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchDollarHistory(e.target.value);
    if (e.target.value === '') {
      setReset(true);
    }
  };

  const handleSearchPresskey = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      getDollarHistory();
    }
  };

  useEffect(() => {
    getAllDollar();
  }, []);

  useEffect(() => {
    if (!reset) {
      return;
    }

    getDollarHistory();
    setReset(false);
  }, [reset]);

  useEffect(() => {
    getDollarHistory(pagination.currentPage);
  }, [order]);

  return (
    <>
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
          <div className="border-b-2 pb-4">
            <Typography variant="h5" color="blue-gray">
              모든 달러 조회
            </Typography>
            <Typography color="gray" className="mt-1 font-normal">
              달러 정보 조회 및 검색 가능.
            </Typography>
          </div>
          <Chip
            className="ml-4 inline w-fit text-sm"
            size="lg"
            variant="ghost"
            value={`총 달러 : $${allDollar}`}
            color="blue"
          />
          <div className="flex w-full shrink-0 gap-2 md:w-max">
            <div className="w-full md:w-72">
              <Input
                label="검색"
                value={searchDollarHistory}
                onChange={handleSearch}
                onKeyDown={handleSearchPresskey}
                icon={
                  searchDollarHistory !== '' ? (
                    <XCircleIcon
                      className="h-5 w-5"
                      onClick={(): void => {
                        setSearchDollarHistory('');
                        setReset(true);
                      }}
                    />
                  ) : (
                    <></>
                  )
                }
              />
            </div>
            <Button
              className="flex items-center gap-3"
              color="blue"
              size="sm"
              onClick={(): Promise<void> => getDollarHistory()}
            >
              <MagnifyingGlassIcon strokeWidth={2} className="h-4 w-4" /> 검색
            </Button>
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-scroll px-0">
        <table className="w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head, index) => (
                <th
                  key={head}
                  className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                  onClick={(): void => {
                    const column = ORDER_COLUMN[index];
                    if (order.column === ORDER_COLUMN[index]) {
                      if (order.order === 'DESC') {
                        setOrder({ column, order: 'ASC' });
                      } else {
                        setOrder({ column, order: 'DESC' });
                      }
                    } else {
                      setOrder({ column: ORDER_COLUMN[index], order: 'DESC' });
                    }
                  }}
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                  >
                    {head}
                    {index !== TABLE_HEAD.length - 1 && (
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    )}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dollarHistories.map(
              ({ id, createdAt, user, dollar, type }, index) => {
                const isLast = index === dollarHistories.length - 1;
                const classes = isLast
                  ? 'p-4'
                  : 'p-4 border-b border-blue-gray-50';

                return (
                  <tr key={id}>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {id}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {timeFormat(createdAt)}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {user?.userId}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="w-max">
                        <Chip
                          size="sm"
                          variant="ghost"
                          value={`$${dollar}`}
                          color={dollar > 0 ? 'blue' : 'red'}
                        />
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {type}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <Tooltip content="포인트 내역 수정">
                        <IconButton variant="text" color="blue-gray">
                          <PencilIcon className="h-4 w-4" />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
        <div className="space-x-2">
          <Button
            variant="outlined"
            color="blue-gray"
            size="sm"
            onClick={(): void => {
              getDollarHistory(1);
            }}
          >
            First
          </Button>
          <Button
            variant="outlined"
            color="blue-gray"
            size="sm"
            onClick={(): void => {
              const previousPage = pagination.currentPage - 1;
              if (previousPage > 0) {
                getDollarHistory(previousPage);
              }
            }}
          >
            Previous
          </Button>
        </div>
        <div className="flex items-center gap-2">
          <DollarHistoryPageButton
            currentPage={pagination.currentPage}
            totalPages={pagination.totalPages}
            goPage={(page: number): Promise<void> => getDollarHistory(page)}
          />
        </div>
        <div className="space-x-2">
          <Button
            variant="outlined"
            color="blue-gray"
            size="sm"
            onClick={(): void => {
              const nextPage = pagination.currentPage + 1;
              if (nextPage <= pagination.totalPages) {
                getDollarHistory(nextPage);
              }
            }}
          >
            Next
          </Button>
          <Button
            variant="outlined"
            color="blue-gray"
            size="sm"
            onClick={(): void => {
              getDollarHistory(pagination.totalPages);
            }}
          >
            Last
          </Button>
        </div>
      </CardFooter>
    </>
  );
}
