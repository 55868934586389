import { ReactElement, useContext, useEffect, useState } from 'react';
import { Datum, ResponsiveLine } from '@nivo/line';
import axios from 'axios';
import { Chip } from '@material-tailwind/react';
import { ChartProps } from '../../types/interface';
import { AuthContext } from '../../context';
import { convertDateString } from '../../utils/DateFormat';

export default function UserNumChart({
  startDate,
  endDate,
}: ChartProps): ReactElement {
  const authToken = useContext(AuthContext);
  const [data, setData] = useState<
    { date: string; sign: number; leave: number }[]
  >([]);
  const [chartData, setChartData] = useState<
    {
      id: string;
      color: string;
      data: Datum[];
    }[]
  >([]);
  const [sum, setSum] = useState<{ sign: number; leave: number }>({
    sign: 0,
    leave: 0,
  });

  const getData = async (): Promise<void> => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/user-num/?start=${startDate}&end=${endDate}T23:59:59.999Z`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      )
      .then((res) => {
        setData(res.data);
      });
  };

  useEffect(() => {
    if (startDate === '' || endDate === '') {
      return;
    }
    if (new Date(endDate).getTime() - new Date(startDate).getTime() < 0) {
      return;
    }

    getData();
  }, [startDate, endDate]);

  useEffect(() => {
    if (data.length < 0) {
      return;
    }
    const result = [];

    const total = { sign: 0, leave: 0 };
    const signData = data.map((value) => {
      total.sign += value.sign;
      const date = new Date(value.date);
      const tz = new Date().getTimezoneOffset() / 60;
      date.setHours(date.getHours() + tz);

      return {
        x: date,
        y: value.sign,
      };
    });

    const leaveData = data.map((value) => {
      total.leave += value.leave;
      const date = new Date(value.date);
      const tz = new Date().getTimezoneOffset() / 60;
      date.setHours(date.getHours() + tz);

      return {
        x: date,
        y: value.leave,
      };
    });
    setSum(total);

    result.push({ id: '탈퇴', color: '', data: leaveData });
    result.push({ id: '가입', color: '', data: signData });

    setChartData(result);
  }, [data]);

  return (
    <div className="flex h-full w-full flex-col">
      <div className="flex items-center justify-center rounded-full">
        <Chip
          value={`총 가입, 탈퇴 : ${sum.sign
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} / ${sum.leave
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
          size="sm"
          variant="ghost"
          color="blue"
          className="w-fit rounded-full"
        />
      </div>
      <ResponsiveLine
        data={chartData}
        margin={{ top: 20, right: 30, bottom: 50, left: 70 }}
        xScale={{
          type: 'time',
          format: '%Y-%m-%d',
          precision: 'day',
        }}
        yScale={{
          type: 'linear',
          min: 0,
          max: 'auto',
          stacked: false,
          reverse: false,
        }}
        yFormat=">-,d"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 36,
          legendPosition: 'middle',
          tickValues: 4,
          format: (value): string => convertDateString(value),
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '탈퇴 / 가입 수',
          legendOffset: -55,
          legendPosition: 'middle',
        }}
        enableSlices="x"
        sliceTooltip={({ slice }): ReactElement => (
          <div
            style={{
              background: 'white',
              padding: '9px 12px',
              border: '1px solid #ccc',
            }}
          >
            <strong>
              {convertDateString(new Date(slice.points[0].data.xFormatted))}
            </strong>
            {slice.points.map((point) => (
              <div key={point.id} className="flex flex-row gap-2 py-1">
                <div
                  style={{
                    color: point.serieColor,
                  }}
                >
                  ■
                </div>
                {point.serieId} <strong>{point.data.yFormatted}</strong>
              </div>
            ))}
          </div>
        )}
        pointColor={{ theme: 'background' }}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
      />
    </div>
  );
}
