import { ChevronUpDownIcon, PencilIcon } from '@heroicons/react/24/solid';
import { MagnifyingGlassIcon, XCircleIcon } from '@heroicons/react/24/outline';
import {
  CardHeader,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  IconButton,
  Tooltip,
  Input,
} from '@material-tailwind/react';
import {
  ChangeEvent,
  KeyboardEvent,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import axios from 'axios';
import { AuthContext } from '../../context';
import timeFormat from '../../utils/timeFormat';
import { PageButtonProps } from '../../types/interface';

const TABLE_HEAD = [
  '유저 아이디',
  '로그인 시간',
  '성별',
  '생년월일',
  '휴대폰 번호',
  '보유 달러',
  '보유 포인트',
  '네이버 아이디',
  '계정 상태',
  '',
];

const ORDER_COLUMN = [
  'sortUserId',
  'sortLoginAt',
  'sortGender',
  'sortBirthDate',
  'sortPhoneNumber',
  'sortDollar',
  'sortPoint',
  'sortNaverId',
  'sortExpireYear',
  '',
];

type UserType = {
  userId: string;
  loginAt: string;
  lastLoginAt: null;
  gender: string;
  birthDate: string;
  phoneNumber: string;
  nickName: string;
  referralCode: string;
  dollar: number;
  point: number;
  bscAddress: null;
  naverId: null;
  expireYear: number;
  date: string;
  status: string;
  account: string;
  accountNumber: string;
  expiry: string;
};

const AllUsersPageButton = ({
  currentPage,
  totalPages,
  goPage,
}: PageButtonProps): ReactElement => {
  const pageButtonIndex = [];
  const maxNum = 10;
  for (let i = currentPage - Math.floor(maxNum / 2); i < currentPage; i += 1) {
    if (i > 0) {
      pageButtonIndex.push(i);
    }
  }

  for (let i = currentPage; i <= totalPages; i += 1) {
    if (pageButtonIndex.length >= maxNum) {
      break;
    }
    pageButtonIndex.push(i);
  }

  return (
    <>
      {pageButtonIndex.map((pageNumber) => (
        <IconButton
          key={pageNumber}
          variant={currentPage === pageNumber ? 'outlined' : 'text'}
          color="blue-gray"
          size="sm"
          onClick={(): void => {
            goPage(pageNumber);
          }}
        >
          {pageNumber}
        </IconButton>
      ))}
    </>
  );
};
const isActive = (loginAt: string, expireYear: number): boolean => {
  const expireDate = new Date(loginAt);
  expireDate.setFullYear(expireDate.getFullYear() + expireYear);

  const diff = expireDate.getTime() - new Date().getTime();
  return diff > 0;
};

export default function AllUsers(): ReactElement {
  const authToken = useContext(AuthContext);

  const [users, setUser] = useState<UserType[]>([]);
  const [pagination, setPagination] = useState<{
    totalPages: number;
    currentPage: number;
  }>({ totalPages: 1, currentPage: 1 });
  const [searchUserId, setSearchUserId] = useState('');
  const [reset, setReset] = useState(false);
  const [order, setOrder] = useState<{ column: string | null; order: string }>({
    column: null,
    order: 'DESC',
  });

  const [dollarCurrency, setDollarCurrency] = useState(0);
  const [userNum, setUserNum] = useState(0);

  const getDollarCurrency = async (): Promise<void> =>
    axios
      .get(`${process.env.REACT_APP_API_URL}/dollar/recent`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((res) => {
        const { data } = res;
        setDollarCurrency(data.price);
      });

  const getUserNum = async (): Promise<void> =>
    axios
      .get(`${process.env.REACT_APP_API_URL}/auth/num`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((res) => {
        const { data } = res;
        setUserNum(data.num);
      });

  const getUsers = async (page = 1, limit = 10): Promise<void> => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/auth${
          searchUserId !== '' ? `/search/${searchUserId}` : ''
        }?page=${page}&limit=${limit}${`${
          order.column ? `&${order.column}=${order.order}` : ''
        }`}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      )
      .then((res) => {
        const { items, meta } = res.data;
        if (items && items.length > 0) {
          setUser(items);
        } else {
          setUser([]);
        }
        if (meta) {
          setPagination({
            totalPages: meta.totalPages,
            currentPage: meta.currentPage,
          });
        }
      })
      .catch(() => {
        setUser([]);
        setPagination({
          totalPages: 1,
          currentPage: 1,
        });
      });
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchUserId(e.target.value);
    if (e.target.value === '') {
      setReset(true);
    }
  };

  const handleSearchPresskey = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      getUsers();
    }
  };

  useEffect(() => {
    if (!reset) {
      return;
    }

    getUsers();
    setReset(false);
  }, [reset]);

  useEffect(() => {
    getUsers(pagination.currentPage);
  }, [order]);

  useEffect(() => {
    getDollarCurrency();
    getUserNum();
  }, []);

  return (
    <>
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
          <div className="items-center">
            <Typography variant="h5" color="blue-gray">
              유저 조회
            </Typography>
            <Typography color="gray" className="mt-1 font-normal">
              유저 정보 조회 및 개별 유저 검색 가능.
            </Typography>
          </div>
          <Chip
            className="ml-4 inline w-fit text-sm"
            size="lg"
            variant="ghost"
            value={`전체 유저 수 : ${userNum.toLocaleString('en-US')} 명`}
            color="blue"
          />
          <div className="flex w-full shrink-0 gap-2 md:w-max">
            <div className="w-full md:w-72">
              <Input
                label="검색"
                value={searchUserId}
                onChange={handleSearch}
                onKeyDown={handleSearchPresskey}
                icon={
                  searchUserId !== '' ? (
                    <XCircleIcon
                      className="h-5 w-5"
                      onClick={(): void => {
                        setSearchUserId('');
                        setReset(true);
                      }}
                    />
                  ) : (
                    <></>
                  )
                }
              />
            </div>
            <Button
              className="flex items-center gap-3"
              color="blue"
              size="sm"
              onClick={(): Promise<void> => getUsers()}
            >
              <MagnifyingGlassIcon strokeWidth={2} className="h-4 w-4" /> 검색
            </Button>
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-scroll px-0">
        <table className="w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head, index) => (
                <th
                  key={head}
                  className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                  onClick={(): void => {
                    const column = ORDER_COLUMN[index];
                    if (order.column === ORDER_COLUMN[index]) {
                      if (order.order === 'DESC') {
                        setOrder({ column, order: 'ASC' });
                      } else {
                        setOrder({ column, order: 'DESC' });
                      }
                    } else {
                      setOrder({ column: ORDER_COLUMN[index], order: 'DESC' });
                    }
                  }}
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                  >
                    {head}
                    {index !== TABLE_HEAD.length - 1 && (
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    )}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {users.map(
              (
                {
                  userId,
                  nickName,
                  loginAt,
                  lastLoginAt,
                  gender,
                  birthDate,
                  phoneNumber,
                  point,
                  dollar,
                  naverId,
                  expireYear,
                },
                index
              ) => {
                const isLast = index === users.length - 1;
                const classes = isLast
                  ? 'p-4'
                  : 'p-4 border-b border-blue-gray-50';

                return (
                  <tr key={userId}>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {userId}
                        </Typography>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal opacity-70"
                        >
                          {nickName}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {timeFormat(loginAt)}
                        </Typography>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal opacity-70"
                        >
                          {timeFormat(lastLoginAt)}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="w-max">
                        <Chip
                          size="sm"
                          variant="ghost"
                          value={gender === 'M' ? '남성' : '여성'}
                          color={gender === 'M' ? 'blue' : 'red'}
                        />
                      </div>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {birthDate}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {phoneNumber}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        ${Math.round(dollar * 100) / 100}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {point.toLocaleString('en-US')} / $
                        {Number(point / dollarCurrency / 115).toFixed(2)}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {naverId || '-'}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <div className="w-max">
                        <Chip
                          size="sm"
                          variant="ghost"
                          value={
                            isActive(loginAt, expireYear) ? '활성' : '휴먼'
                          }
                          color={
                            isActive(loginAt, expireYear) ? 'green' : 'gray'
                          }
                        />
                      </div>
                    </td>
                    <td className={classes}>
                      <Tooltip content="유저 수정">
                        <IconButton variant="text" color="blue-gray">
                          <PencilIcon className="h-4 w-4" />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
        <div className="space-x-2">
          <Button
            variant="outlined"
            color="blue-gray"
            size="sm"
            onClick={(): void => {
              getUsers(1);
            }}
          >
            First
          </Button>
          <Button
            variant="outlined"
            color="blue-gray"
            size="sm"
            onClick={(): void => {
              const previousPage = pagination.currentPage - 1;
              if (previousPage > 0) {
                getUsers(previousPage);
              }
            }}
          >
            Previous
          </Button>
        </div>
        <div className="flex items-center gap-2">
          <AllUsersPageButton
            currentPage={pagination.currentPage}
            totalPages={pagination.totalPages}
            goPage={(page: number): Promise<void> => getUsers(page)}
          />
        </div>
        <div className="space-x-2">
          <Button
            variant="outlined"
            color="blue-gray"
            size="sm"
            onClick={(): void => {
              const nextPage = pagination.currentPage + 1;
              if (nextPage <= pagination.totalPages) {
                getUsers(nextPage);
              }
            }}
          >
            Next
          </Button>
          <Button
            variant="outlined"
            color="blue-gray"
            size="sm"
            onClick={(): void => {
              getUsers(pagination.totalPages);
            }}
          >
            Last
          </Button>
        </div>
      </CardFooter>
    </>
  );
}
