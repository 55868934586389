import { ChevronUpDownIcon, PencilIcon } from '@heroicons/react/24/solid';
import { MagnifyingGlassIcon, XCircleIcon } from '@heroicons/react/24/outline';
import {
  CardHeader,
  Typography,
  Button,
  CardBody,
  CardFooter,
  IconButton,
  Tooltip,
  Input,
} from '@material-tailwind/react';
import {
  ChangeEvent,
  KeyboardEvent,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import axios from 'axios';
import { AuthContext } from '../../context';
import timeFormat from '../../utils/timeFormat';
import { PageButtonProps } from '../../types/interface';
import { MineType } from '../../types/type';

const TABLE_HEAD = [
  '아이디',
  '생성 시간',
  '유저',
  '채굴 속도',
  '채굴 용량',
  '강화권',
  '최근 채굴 날짜',
  '',
];

const ORDER_COLUMN = [
  'sortId',
  'sortCreatedAt',
  'sortSearchData',
  'sortStorageLevel',
  'sortSpeedLevel',
  'sortUpgradeTicket',
  'sortLastMineTime',
  '',
];

const MinePageButton = ({
  currentPage,
  totalPages,
  goPage,
}: PageButtonProps): ReactElement => {
  const pageButtonIndex = [];
  const maxNum = 10;
  for (let i = currentPage - Math.floor(maxNum / 2); i < currentPage; i += 1) {
    if (i > 0) {
      pageButtonIndex.push(i);
    }
  }

  for (let i = currentPage; i <= totalPages; i += 1) {
    if (pageButtonIndex.length >= maxNum) {
      break;
    }
    pageButtonIndex.push(i);
  }

  return (
    <>
      {pageButtonIndex.map((pageNumber) => (
        <IconButton
          key={pageNumber}
          variant={currentPage === pageNumber ? 'outlined' : 'text'}
          color="blue-gray"
          size="sm"
          onClick={(): void => {
            goPage(pageNumber);
          }}
        >
          {pageNumber}
        </IconButton>
      ))}
    </>
  );
};

export default function Mine(): ReactElement {
  const authToken = useContext(AuthContext);

  const [mines, setMines] = useState<MineType[]>([]);
  const [pagination, setPagination] = useState<{
    totalPages: number;
    currentPage: number;
  }>({ totalPages: 1, currentPage: 1 });
  const [searchData, setSearchData] = useState('');
  const [reset, setReset] = useState(false);
  const [order, setOrder] = useState<{ column: string | null; order: string }>({
    column: null,
    order: 'DESC',
  });

  const [openDialog, setOpenDialog] = useState<{ [idx: number]: boolean }>({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
  });

  const getMine = async (page = 1, limit = 10): Promise<void> => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/mine${
          searchData !== '' ? `/search/${searchData}` : ''
        }?page=${page}&limit=${limit}${`${
          order.column ? `&${order.column}=${order.order}` : ''
        }`}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      )
      .then((res) => {
        const { items, meta } = res.data;
        if (items && items.length > 0) {
          setMines(items);
        } else {
          setMines([]);
        }
        if (meta) {
          setPagination({
            totalPages: meta.totalPages,
            currentPage: meta.currentPage,
          });
        }
      })
      .catch(() => {
        setMines([]);
        setPagination({
          totalPages: 1,
          currentPage: 1,
        });
      });
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchData(e.target.value);
    if (e.target.value === '') {
      setReset(true);
    }
  };

  const handleSearchPresskey = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      getMine();
    }
  };

  useEffect(() => {
    if (!reset) {
      return;
    }

    getMine();
    setReset(false);
  }, [reset]);

  useEffect(() => {
    getMine(pagination.currentPage);
  }, [order]);

  return (
    <>
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
          <div className="border-b-2 pb-4">
            <Typography variant="h5" color="blue-gray">
              채굴소 데이터 조회
            </Typography>
            <Typography color="gray" className="mt-1 font-normal">
              채굴소 데이터 조회 및 검색 가능.
            </Typography>
          </div>
          <div className="flex w-full shrink-0 gap-2 md:w-max">
            <div className="w-full md:w-72">
              <Input
                label="유저 검색"
                value={searchData}
                onChange={handleSearch}
                onKeyDown={handleSearchPresskey}
                icon={
                  searchData !== '' ? (
                    <XCircleIcon
                      className="h-5 w-5"
                      onClick={(): void => {
                        setSearchData('');
                        setReset(true);
                      }}
                    />
                  ) : (
                    <></>
                  )
                }
              />
            </div>
            <Button
              className="flex items-center gap-3"
              color="blue"
              size="sm"
              onClick={(): Promise<void> => getMine()}
            >
              <MagnifyingGlassIcon strokeWidth={2} className="h-4 w-4" /> 검색
            </Button>
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-scroll px-0">
        <table className="w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head, index) => (
                <th
                  key={head}
                  className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                  onClick={(): void => {
                    const column = ORDER_COLUMN[index];
                    if (order.column === ORDER_COLUMN[index]) {
                      if (order.order === 'DESC') {
                        setOrder({ column, order: 'ASC' });
                      } else {
                        setOrder({ column, order: 'DESC' });
                      }
                    } else {
                      setOrder({ column: ORDER_COLUMN[index], order: 'DESC' });
                    }
                  }}
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                  >
                    {head}
                    {index !== TABLE_HEAD.length - 1 && (
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    )}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {mines.map(
              (
                {
                  id,
                  createdAt,
                  user,
                  upgradeTicket,
                  storageLevel,
                  speedLevel,
                  lastMineTime,
                },
                index
              ) => {
                const isLast = index === mines.length - 1;
                const classes = isLast
                  ? 'p-4'
                  : 'p-4 border-b border-blue-gray-50';

                return (
                  <tr key={id}>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {id}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {timeFormat(createdAt)}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {user?.userId}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {storageLevel}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {speedLevel}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="w-max">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {upgradeTicket}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="w-max">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {timeFormat(lastMineTime)}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <>
                        <Tooltip content="채굴소 데이터 수정">
                          <IconButton
                            variant="text"
                            color="blue-gray"
                            onClick={(): void => {
                              setOpenDialog({ ...openDialog, [index]: true });
                            }}
                          >
                            <PencilIcon className="h-4 w-4" />
                          </IconButton>
                        </Tooltip>
                      </>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
        <div className="space-x-2">
          <Button
            variant="outlined"
            color="blue-gray"
            size="sm"
            onClick={(): void => {
              getMine(1);
            }}
          >
            First
          </Button>
          <Button
            variant="outlined"
            color="blue-gray"
            size="sm"
            onClick={(): void => {
              const previousPage = pagination.currentPage - 1;
              if (previousPage > 0) {
                getMine(previousPage);
              }
            }}
          >
            Previous
          </Button>
        </div>
        <div className="flex items-center gap-2">
          <MinePageButton
            currentPage={pagination.currentPage}
            totalPages={pagination.totalPages}
            goPage={(page: number): Promise<void> => getMine(page)}
          />
        </div>
        <div className="space-x-2">
          <Button
            variant="outlined"
            color="blue-gray"
            size="sm"
            onClick={(): void => {
              const nextPage = pagination.currentPage + 1;
              if (nextPage <= pagination.totalPages) {
                getMine(nextPage);
              }
            }}
          >
            Next
          </Button>
          <Button
            variant="outlined"
            color="blue-gray"
            size="sm"
            onClick={(): void => {
              getMine(pagination.totalPages);
            }}
          >
            Last
          </Button>
        </div>
      </CardFooter>
    </>
  );
}
