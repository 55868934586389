import { ReactElement, useEffect, useState } from 'react';
import { Button, Card } from '@material-tailwind/react';
import DauChart from '../User/DauChart';
import { convertDateString } from '../../utils/DateFormat';
import { DatePicker } from '../Common/DatePicker';
import { ApplovinChart, TnkChart, UserNumChart } from '.';

export default function Dashboard(): ReactElement {
  const [number, setNumber] = useState(7);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    if (number < 0) {
      return;
    }
    const end = new Date();
    const start = new Date();
    start.setDate(start.getDate() - number + 1);
    setStartDate(convertDateString(start));
    setEndDate(convertDateString(end));
  }, [number]);

  useEffect(() => {
    const gap =
      (new Date(endDate).getTime() - new Date(startDate).getTime()) /
        1000 /
        86400 +
      1;
    if (gap === number) {
      setNumber(-1);
    }
  }, [startDate, endDate]);

  return (
    <div className="flex h-full w-full flex-col gap-2">
      <div className="flex w-full flex-row items-center justify-center gap-2">
        <Button className="flex-1 text-base" onClick={(): void => setNumber(1)}>
          1일
        </Button>
        <Button className="flex-1 text-base" onClick={(): void => setNumber(7)}>
          1주
        </Button>
        <Button
          className="flex-1 text-base"
          onClick={(): void => setNumber(14)}
        >
          2주
        </Button>
        <Button
          className="flex-1 text-base"
          onClick={(): void => setNumber(30)}
        >
          1달
        </Button>
        <Button
          className="flex-1 text-base"
          onClick={(): void => {
            const before = new Date();
            before.setDate(1);
            const now = new Date();
            setStartDate(convertDateString(before));
            setEndDate(convertDateString(now));
          }}
        >
          이번 달
        </Button>
        <Button
          className="flex-1 text-base"
          onClick={(): void => {
            const start = new Date();
            start.setMonth(start.getMonth() - 1);
            start.setDate(1);
            const end = new Date();
            end.setDate(1);
            end.setDate(end.getDate() - 1);
            setStartDate(convertDateString(start));
            setEndDate(convertDateString(end));
          }}
        >
          지난 달
        </Button>
        <DatePicker date={startDate} setDate={setStartDate} />
        <DatePicker date={endDate} setDate={setEndDate} />
      </div>
      <div className="grid h-full w-full flex-1 grid-cols-2 grid-rows-2 gap-4">
        <Card>
          <DauChart startDate={startDate} endDate={endDate} />
        </Card>
        <Card>
          <UserNumChart startDate={startDate} endDate={endDate} />
        </Card>
        <Card>
          <ApplovinChart startDate={startDate} endDate={endDate} />
        </Card>
        <Card>
          <TnkChart startDate={startDate} endDate={endDate} />
        </Card>
      </div>
    </div>
  );
}
