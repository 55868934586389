import { ChevronUpDownIcon, PencilIcon } from '@heroicons/react/24/solid';
import { MagnifyingGlassIcon, XCircleIcon } from '@heroicons/react/24/outline';
import {
  CardHeader,
  Typography,
  Button,
  CardBody,
  CardFooter,
  IconButton,
  Tooltip,
  Input,
  Chip,
} from '@material-tailwind/react';
import {
  ChangeEvent,
  KeyboardEvent,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import axios from 'axios';
import { AuthContext } from '../../context';
import timeFormat from '../../utils/timeFormat';
import { PageButtonProps } from '../../types/interface';
import { PointHistoryType } from '../../types/type';

const TABLE_HEAD = [
  '아이디',
  '생성 시간',
  '유저 아이디',
  '포인트',
  '타입',
  '광고 네트워크',
  '광고 트랜잭션',
  '',
];
const ORDER_COLUMN = [
  'sortId',
  'sortCreatedAt',
  'sortUserId',
  'sortPoint',
  'sortType',
  'sortAdNetwork',
  'sortTransactionId',
  '',
];

const UserPointHistoryPageButton = ({
  currentPage,
  totalPages,
  goPage,
}: PageButtonProps): ReactElement => {
  const pageButtonIndex = [];
  const maxNum = 10;
  for (let i = currentPage - Math.floor(maxNum / 2); i < currentPage; i += 1) {
    if (i > 0) {
      pageButtonIndex.push(i);
    }
  }

  for (let i = currentPage; i <= totalPages; i += 1) {
    if (pageButtonIndex.length >= maxNum) {
      break;
    }
    pageButtonIndex.push(i);
  }

  return (
    <>
      {pageButtonIndex.map((pageNumber) => (
        <IconButton
          key={pageNumber}
          variant={currentPage === pageNumber ? 'outlined' : 'text'}
          color="blue-gray"
          size="sm"
          onClick={(): void => {
            goPage(pageNumber);
          }}
        >
          {pageNumber}
        </IconButton>
      ))}
    </>
  );
};

export default function UserPointHistory(): ReactElement {
  const authToken = useContext(AuthContext);

  const [pointHistories, setUserPointHistorys] = useState<PointHistoryType[]>(
    []
  );
  const [pagination, setPagination] = useState<{
    totalPages: number;
    currentPage: number;
  }>({ totalPages: 1, currentPage: 1 });
  const [userId, setUserId] = useState('');
  const [searchUserPointHistory, setSearchUserPointHistory] = useState('');
  const [reset, setReset] = useState(false);

  const [allPoint, setAllPoint] = useState(0);
  const [dollarCurrency, setDollarCurrency] = useState(0);
  const [order, setOrder] = useState<{ column: string | null; order: string }>({
    column: null,
    order: 'DESC',
  });

  const getDollarCurrency = async (): Promise<void> =>
    axios
      .get(`${process.env.REACT_APP_API_URL}/dollar/recent`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((res) => {
        const { data } = res;
        setDollarCurrency(data.price);
      });

  const getAllPoint = async (): Promise<void> => {
    if (userId === '') {
      return;
    }

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/point-history/point/${userId}${`${
          order.column ? `?${order.column}=${order.order}` : ''
        }`}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      )
      .then((res) => {
        const { data } = res;
        setAllPoint(data.point);
      })
      .catch(() => {
        setAllPoint(0);
      });
  };

  const getPointHistory = async (page = 1, limit = 10): Promise<void> => {
    if (userId === '') {
      return;
    }

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/point-history/${userId}${
          searchUserPointHistory !== ''
            ? `/search/${searchUserPointHistory}`
            : ''
        }?page=${page}&limit=${limit}${`${
          order.column ? `&${order.column}=${order.order}` : ''
        }`}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      )
      .then((res) => {
        const { items, meta } = res.data;
        if (items && items.length > 0) {
          setUserPointHistorys(items);
        } else {
          setUserPointHistorys([]);
        }
        if (meta) {
          setPagination({
            totalPages: meta.totalPages,
            currentPage: meta.currentPage,
          });
        }
      })
      .catch(() => {
        setUserPointHistorys([]);
        setPagination({
          totalPages: 1,
          currentPage: 1,
        });
      });
  };

  const handleUserSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    setUserId(e.target.value);
    if (e.target.value === '') {
      setReset(true);
    }
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchUserPointHistory(e.target.value);
    if (e.target.value === '') {
      setReset(true);
    }
  };

  const handleSearchPresskey = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      getPointHistory();
      getAllPoint();
    }
  };

  useEffect(() => {
    if (!reset) {
      return;
    }

    if (userId === '' && searchUserPointHistory === '') {
      setUserPointHistorys([]);
      setPagination({
        totalPages: 1,
        currentPage: 1,
      });
      setAllPoint(0);
    } else if (userId !== '') {
      getPointHistory();
      getAllPoint();
    }

    setReset(false);
  }, [reset]);

  useEffect(() => {
    if (userId !== '') {
      getPointHistory(pagination.currentPage);
    }
  }, [order]);

  useEffect(() => {
    getDollarCurrency();
  }, []);

  return (
    <>
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
          <div className="border-b-2 pb-4">
            <Typography variant="h5" color="blue-gray">
              계정 포인트 조회
            </Typography>
            <Typography color="gray" className="mt-1 font-normal">
              계정 포인트 정보 조회 및 검색 가능.
            </Typography>
            {allPoint ? (
              <Chip
                className="w-fit"
                size="sm"
                variant="ghost"
                value={`${allPoint
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${
                  dollarCurrency ? ` = $${allPoint / dollarCurrency / 115}` : ''
                }`}
                color="blue"
              />
            ) : (
              ''
            )}
          </div>
          <div className="flex w-full shrink-0 gap-2 md:w-max">
            <div className="w-full md:w-72">
              <Input
                label="유저 검색"
                value={userId}
                onChange={handleUserSearch}
                onKeyDown={handleSearchPresskey}
                icon={
                  userId !== '' ? (
                    <XCircleIcon
                      className="h-5 w-5"
                      onClick={(): void => {
                        setUserId('');
                        setReset(true);
                      }}
                    />
                  ) : (
                    <></>
                  )
                }
              />
            </div>
            <Button
              className="flex items-center gap-3"
              color="blue"
              size="sm"
              onClick={(): void => {
                getPointHistory();
                getAllPoint();
              }}
            >
              <MagnifyingGlassIcon strokeWidth={2} className="h-4 w-4" /> 검색
            </Button>
          </div>
          <div className="flex w-full shrink-0 gap-2 md:w-max">
            <div className="w-full md:w-72">
              <Input
                label="검색"
                value={searchUserPointHistory}
                onChange={handleSearch}
                onKeyDown={handleSearchPresskey}
                icon={
                  searchUserPointHistory !== '' ? (
                    <XCircleIcon
                      className="h-5 w-5"
                      onClick={(): void => {
                        setSearchUserPointHistory('');
                        setReset(true);
                      }}
                    />
                  ) : (
                    <></>
                  )
                }
              />
            </div>
            <Button
              className="flex items-center gap-3"
              color="blue"
              size="sm"
              onClick={(): Promise<void> => getPointHistory()}
            >
              <MagnifyingGlassIcon strokeWidth={2} className="h-4 w-4" /> 검색
            </Button>
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-scroll px-0">
        <table className="w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head, index) => (
                <th
                  key={head}
                  className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                  onClick={(): void => {
                    const column = ORDER_COLUMN[index];
                    if (order.column === ORDER_COLUMN[index]) {
                      if (order.order === 'DESC') {
                        setOrder({ column, order: 'ASC' });
                      } else {
                        setOrder({ column, order: 'DESC' });
                      }
                    } else {
                      setOrder({ column: ORDER_COLUMN[index], order: 'DESC' });
                    }
                  }}
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                  >
                    {head}
                    {index !== TABLE_HEAD.length - 1 && (
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    )}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {pointHistories.map(
              (
                { id, transactionId, createdAt, user, point, adNetwork, type },
                index
              ) => {
                const isLast = index === pointHistories.length - 1;
                const classes = isLast
                  ? 'p-4'
                  : 'p-4 border-b border-blue-gray-50';

                return (
                  <tr key={id}>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {id}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {timeFormat(createdAt)}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {user?.userId}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="w-max">
                        <Chip
                          size="sm"
                          variant="ghost"
                          value={point
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          color={point > 0 ? 'blue' : 'red'}
                        />
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {type}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {adNetwork || '-'}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {transactionId || '-'}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <Tooltip content="포인트 내역 수정">
                        <IconButton variant="text" color="blue-gray">
                          <PencilIcon className="h-4 w-4" />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
        <div className="space-x-2">
          <Button
            variant="outlined"
            color="blue-gray"
            size="sm"
            onClick={(): void => {
              getPointHistory(1);
            }}
          >
            First
          </Button>
          <Button
            variant="outlined"
            color="blue-gray"
            size="sm"
            onClick={(): void => {
              const previousPage = pagination.currentPage - 1;
              if (previousPage > 0) {
                getPointHistory(previousPage);
              }
            }}
          >
            Previous
          </Button>
        </div>
        <div className="flex items-center gap-2">
          <UserPointHistoryPageButton
            currentPage={pagination.currentPage}
            totalPages={pagination.totalPages}
            goPage={(page: number): Promise<void> => getPointHistory(page)}
          />
        </div>
        <div className="space-x-2">
          <Button
            variant="outlined"
            color="blue-gray"
            size="sm"
            onClick={(): void => {
              const nextPage = pagination.currentPage + 1;
              if (nextPage <= pagination.totalPages) {
                getPointHistory(nextPage);
              }
            }}
          >
            Next
          </Button>
          <Button
            variant="outlined"
            color="blue-gray"
            size="sm"
            onClick={(): void => {
              getPointHistory(pagination.totalPages);
            }}
          >
            Last
          </Button>
        </div>
      </CardFooter>
    </>
  );
}
