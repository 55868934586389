import { ReactElement, useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { Login, Main } from './components/Pages';
import { AuthContext } from './context';

function App(): ReactElement {
  const [token, setToken] = useState('');

  const checkToken = (): void => {
    if (token === '') {
      return;
    }

    const decoded: { userId: string; iat: number; exp: number } =
      jwtDecode(token);
    const now = new Date().getTime() / 1000;

    if (decoded.exp - now < 0) {
      setToken('');
    }
  };

  useEffect(() => {
    const defaultData = localStorage.getItem('auth') ?? '';
    setToken(defaultData);

    const interval = setInterval(() => {
      const authToken = localStorage.getItem('auth') ?? '';
      setToken(authToken);
      checkToken();
    }, 50);

    return (): void => {
      clearInterval(interval);
    };
  }, []);
  return (
    <AuthContext.Provider value={token}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login setToken={setToken} />} />
          <Route path="/main" element={<Main />} />
          <Route path="/*" element={<Login setToken={setToken} />} />
        </Routes>
      </BrowserRouter>
      <div className="App"></div>
    </AuthContext.Provider>
  );
}

export default App;
