import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Radio,
  Textarea,
} from '@material-tailwind/react';
import { ReactElement, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context';
import {
  BuyHistoryType,
  BuyStatus,
  BuyType,
  BuyTypeToKor,
} from '../../types/type';

export default function EditBuyHistory({
  buyHistory,
  isOpen,
  setIsOpen,
}: {
  buyHistory: BuyHistoryType;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => Promise<void>;
}): ReactElement {
  const authToken = useContext(AuthContext);

  const [content, setContent] = useState<string | null>(null);

  const [status, setStatus] = useState<BuyStatus>(buyHistory.status);

  const saveBuyHistory = async (): Promise<void> => {
    const payload = { ...buyHistory, status };
    if (status === BuyStatus.FAILURE) {
      payload.failureInfo = content;
    } else if (status === BuyStatus.SUCCESS) {
      payload.successInfo = content;
    }

    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/buy-history/${buyHistory.id}`,
        payload,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      )
      .catch(() => {});
  };

  useEffect(() => {
    if (status === BuyStatus.FAILURE) {
      setContent(buyHistory.failureInfo);
    } else if (status === BuyStatus.SUCCESS) {
      setContent(buyHistory.successInfo);
    }
  }, [status]);

  return (
    <>
      <Dialog open={isOpen} handler={setIsOpen}>
        <DialogHeader>{`${
          BuyTypeToKor[buyHistory.type]
        } 구매 요청`}</DialogHeader>
        <DialogBody divider className="">
          <div className="flex flex-col gap-4">
            <div className="text-lg font-medium text-black">
              아이디 : {buyHistory.user.userId}
            </div>
            <div className="text-lg font-medium text-black">
              타입 : {BuyTypeToKor[buyHistory.type]}
            </div>
            <div className="text-lg font-medium text-black">
              구매 금액 : ₩
              {buyHistory.amount
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
              / ${buyHistory.usedDollar.toFixed(2)}
            </div>

            <div className="text-lg font-medium text-black">
              {buyHistory.type === BuyType.NAVER_PAY
                ? '네이버 아이디'
                : '휴대폰 번호'}{' '}
              : {buyHistory.idOrPhoneNumber}
            </div>
            {status !== BuyStatus.PENDING && (
              <Textarea
                label={`${status === BuyStatus.SUCCESS ? '성공' : '실패'} 내용`}
                resize={true}
                value={content || ''}
                onChange={(e): void => {
                  setContent(e.target.value);
                }}
              />
            )}
            <div className="flex gap-20">
              <Radio
                labelProps={{ className: 'font-medium text-lg' }}
                name="type"
                label="대기"
                id="1"
                defaultChecked
                checked={status === BuyStatus.PENDING}
                onChange={(): void => {
                  setStatus(BuyStatus.PENDING);
                }}
              />
              <Radio
                labelProps={{ className: 'font-medium text-lg' }}
                name="type"
                label="성공"
                id="2"
                defaultChecked
                checked={status === BuyStatus.SUCCESS}
                onChange={(): void => {
                  setStatus(BuyStatus.SUCCESS);
                }}
              />
              <Radio
                labelProps={{ className: 'font-medium text-lg' }}
                label="실패"
                id="3"
                checked={status === BuyStatus.FAILURE}
                onChange={(): void => {
                  setStatus(BuyStatus.FAILURE);
                }}
              />
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={async (): Promise<void> => {
              await saveBuyHistory();
              setTimeout(() => {
                setIsOpen(false);
              }, 1000);
            }}
            className="mr-1"
          >
            <span>저장</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={(): void => {
              setIsOpen(false);
            }}
          >
            <span>취소</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
