import { ChevronUpDownIcon, PencilIcon } from '@heroicons/react/24/solid';
import {
  CardHeader,
  Typography,
  Button,
  CardBody,
  CardFooter,
  IconButton,
  Tooltip,
  Chip,
} from '@material-tailwind/react';
import { ReactElement, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context';
import timeFormat from '../../utils/timeFormat';
import { PageButtonProps } from '../../types/interface';
import { BuyHistoryType, BuyStatus } from '../../types/type';
import EditBuyHistory from './EditBuyHistory';

const TABLE_HEAD = [
  '아이디',
  '생성 시간',
  '유저 아이디',
  '상태',
  '타입',
  'ID / Phone',
  '구매량',
  '사용한 달러',
  '성공 정보',
  '실패 정보',
  '',
];

const ORDER_COLUMN = [
  'sortId',
  'sortCreatedAt',
  'sortUserId',
  'sortStatus',
  'sortType',
  'sortIdOrPhoneNumber',
  'sortAmount',
  'sortUsedDollar',
  'sortSuccessInfo',
  'sortFailureInfo',
  '',
];

const WaitBuyHistoryPageButton = ({
  currentPage,
  totalPages,
  goPage,
}: PageButtonProps): ReactElement => {
  const pageButtonIndex = [];
  const maxNum = 10;
  for (let i = currentPage - Math.floor(maxNum / 2); i < currentPage; i += 1) {
    if (i > 0) {
      pageButtonIndex.push(i);
    }
  }

  for (let i = currentPage; i <= totalPages; i += 1) {
    if (pageButtonIndex.length >= maxNum) {
      break;
    }
    pageButtonIndex.push(i);
  }

  return (
    <>
      {pageButtonIndex.map((pageNumber) => (
        <IconButton
          key={pageNumber}
          variant={currentPage === pageNumber ? 'outlined' : 'text'}
          color="blue-gray"
          size="sm"
          onClick={(): void => {
            goPage(pageNumber);
          }}
        >
          {pageNumber}
        </IconButton>
      ))}
    </>
  );
};

export default function WaitBuyHistory(): ReactElement {
  const authToken = useContext(AuthContext);

  const [buyHistories, setBuyHistorys] = useState<BuyHistoryType[]>([]);
  const [pagination, setPagination] = useState<{
    totalPages: number;
    currentPage: number;
  }>({ totalPages: 1, currentPage: 1 });
  const [reset, setReset] = useState(false);
  const [order, setOrder] = useState<{ column: string | null; order: string }>({
    column: null,
    order: 'DESC',
  });
  const [openDialog, setOpenDialog] = useState<{ [idx: number]: boolean }>({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
  });

  const getBuyHistory = async (page = 1, limit = 10): Promise<void> => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/buy-history?status=PENDING&page=${page}&limit=${limit}${`${
          order.column ? `&${order.column}=${order.order}` : ''
        }`}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      )
      .then((res) => {
        const { items, meta } = res.data;
        if (items && items.length > 0) {
          setBuyHistorys(items);
        } else {
          setBuyHistorys([]);
        }
        if (meta) {
          setPagination({
            totalPages: meta.totalPages,
            currentPage: meta.currentPage,
          });
        }
      })
      .catch(() => {
        setBuyHistorys([]);
        setPagination({
          totalPages: 1,
          currentPage: 1,
        });
      });
  };

  useEffect(() => {
    if (!reset) {
      return;
    }

    getBuyHistory();
    setReset(false);
  }, [reset]);

  useEffect(() => {
    getBuyHistory(pagination.currentPage);
  }, [order]);

  return (
    <>
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
          <div className="border-b-2 pb-4">
            <Typography variant="h5" color="blue-gray">
              미 확인 구매 조회
            </Typography>
            <Typography color="gray" className="mt-1 font-normal">
              미 확인 구매 정보 조회
            </Typography>
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-scroll px-0">
        <table className="w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head, index) => (
                <th
                  key={head}
                  className="cursor-buyer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                  onClick={(): void => {
                    const column = ORDER_COLUMN[index];
                    if (order.column === ORDER_COLUMN[index]) {
                      if (order.order === 'DESC') {
                        setOrder({ column, order: 'ASC' });
                      } else {
                        setOrder({ column, order: 'DESC' });
                      }
                    } else {
                      setOrder({ column: ORDER_COLUMN[index], order: 'DESC' });
                    }
                  }}
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                  >
                    {head}
                    {index !== TABLE_HEAD.length - 1 && (
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    )}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {buyHistories.map(
              (
                {
                  id,
                  createdAt,
                  user,
                  status,
                  type,
                  idOrPhoneNumber,
                  amount,
                  usedDollar,
                  successInfo,
                  failureInfo,
                },
                index
              ) => {
                const isLast = index === buyHistories.length - 1;
                const classes = isLast
                  ? 'p-4'
                  : 'p-4 border-b border-blue-gray-50';

                // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                const getStatusColor = () => {
                  switch (status) {
                    case BuyStatus.FAILURE:
                      return 'red';
                    case BuyStatus.SUCCESS:
                      return 'blue';
                    case BuyStatus.PENDING:
                    default:
                      return 'gray';
                  }
                };
                return (
                  <tr key={id}>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {id}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {timeFormat(createdAt)}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {user?.userId}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="w-max">
                        <Chip
                          size="sm"
                          variant="ghost"
                          value={status
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          color={getStatusColor()}
                        />
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {type}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {idOrPhoneNumber || '-'}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {amount}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          ${usedDollar}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {successInfo?.substring(0, 10)}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold"
                        >
                          {failureInfo?.substring(0, 10)}
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <Tooltip content="구매 내역 수정">
                        <IconButton
                          variant="text"
                          color="blue-gray"
                          onClick={(): void => {
                            setOpenDialog({ ...openDialog, [index]: true });
                          }}
                        >
                          <PencilIcon className="h-4 w-4" />
                        </IconButton>
                      </Tooltip>
                      <EditBuyHistory
                        buyHistory={buyHistories[index]}
                        isOpen={openDialog[index]}
                        setIsOpen={async (open: boolean): Promise<void> => {
                          if (!open) {
                            await getBuyHistory(pagination.currentPage);
                          }
                          setOpenDialog({ ...openDialog, [index]: open });
                        }}
                      />
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
        <div className="space-x-2">
          <Button
            variant="outlined"
            color="blue-gray"
            size="sm"
            onClick={(): void => {
              getBuyHistory(1);
            }}
          >
            First
          </Button>
          <Button
            variant="outlined"
            color="blue-gray"
            size="sm"
            onClick={(): void => {
              const previousPage = pagination.currentPage - 1;
              if (previousPage > 0) {
                getBuyHistory(previousPage);
              }
            }}
          >
            Previous
          </Button>
        </div>
        <div className="flex items-center gap-2">
          <WaitBuyHistoryPageButton
            currentPage={pagination.currentPage}
            totalPages={pagination.totalPages}
            goPage={(page: number): Promise<void> => getBuyHistory(page)}
          />
        </div>
        <div className="space-x-2">
          <Button
            variant="outlined"
            color="blue-gray"
            size="sm"
            onClick={(): void => {
              const nextPage = pagination.currentPage + 1;
              if (nextPage <= pagination.totalPages) {
                getBuyHistory(nextPage);
              }
            }}
          >
            Next
          </Button>
          <Button
            variant="outlined"
            color="blue-gray"
            size="sm"
            onClick={(): void => {
              getBuyHistory(pagination.totalPages);
            }}
          >
            Last
          </Button>
        </div>
      </CardFooter>
    </>
  );
}
