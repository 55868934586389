/* eslint-disable import/prefer-default-export */
const convertDateString = (date: Date): string => {
  const mYear = date.getFullYear();
  const mMonth =
    date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
  const mDate = date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`;

  return `${mYear}-${mMonth}-${mDate}`;
};

export { convertDateString };
