/* eslint-disable import/no-extraneous-dependencies */
import { ReactElement, useEffect, useState } from 'react';
import { Button } from '@material-tailwind/react';
import { convertDateString } from '../../utils/DateFormat';
import { DatePicker } from '../Common/DatePicker';
import DauChart from './DauChart';

export interface IDau {
  className?: string;
}

export default function Dau({ className }: IDau): ReactElement {
  const [number, setNumber] = useState(7);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    if (number < 0) {
      return;
    }
    const end = new Date();
    const start = new Date();
    start.setDate(start.getDate() - number + 1);
    setStartDate(convertDateString(start));
    setEndDate(convertDateString(end));
  }, [number]);

  useEffect(() => {
    const gap =
      (new Date(endDate).getTime() - new Date(startDate).getTime()) /
        1000 /
        86400 +
      1;
    if (gap !== number) {
      setNumber(-1);
    }
  }, [startDate, endDate]);

  return (
    <>
      <div className={`${className} flex h-full flex-col`}>
        <div className="flex w-full flex-row items-center justify-center gap-2">
          <Button
            className="flex-1 text-base"
            onClick={(): void => setNumber(7)}
          >
            1주
          </Button>
          <Button
            className="flex-1 text-base"
            onClick={(): void => setNumber(14)}
          >
            2주
          </Button>
          <Button
            className="flex-1 text-base"
            onClick={(): void => setNumber(30)}
          >
            1달
          </Button>
          <DatePicker date={startDate} setDate={setStartDate} />
          <DatePicker date={endDate} setDate={setEndDate} />
        </div>
        <DauChart startDate={startDate} endDate={endDate} />
      </div>
    </>
  );
}
