import {
  ChangeEvent,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import axios from 'axios';
import * as CryptoJS from 'crypto-js';
import { Button, Card, Input, Typography } from '@material-tailwind/react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context';

interface LoginProps {
  setToken: (token: string) => void;
}

export default function Login({ setToken }: LoginProps): ReactElement {
  const authToken = useContext(AuthContext);

  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');

  const handleUserId = (e: ChangeEvent<HTMLInputElement>): void => {
    setUserId(e.target.value);
  };

  const handlePassword = (e: ChangeEvent<HTMLInputElement>): void => {
    setPassword(e.target.value);
  };

  const login = (): void => {
    const hash = CryptoJS.SHA256(userId + process.env.REACT_APP_SECRET);
    const secret = CryptoJS.enc.Hex.stringify(hash);
    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/signIn`, {
        userId,
        password,
        secret,
      })
      .then((res) => {
        setToken(res.data.accessToken);
      })
      .catch(() => setToken(authToken));
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (authToken !== '') {
      localStorage.setItem('auth', authToken);
      navigate('/main', { replace: true });
    }
  }, [authToken]);

  return (
    <div className="flex h-screen items-center justify-center">
      <Card color="transparent" className="p-10">
        <Typography variant="h4" color="blue-gray" className="mx-auto">
          달러 캐시 관리자 페이지
        </Typography>
        <form
          className="w-50 mb-2 mt-8 max-w-screen-lg sm:w-96"
          onSubmit={(e): void => {
            login();
            e.preventDefault();
          }}
        >
          <div className="mb-4 flex flex-col gap-6">
            <Input
              size="lg"
              label="ID"
              value={userId}
              onChange={handleUserId}
            />
            <Input
              type="password"
              size="lg"
              label="Password"
              value={password}
              onChange={handlePassword}
            />
          </div>
          <Button type="submit" className="mt-6 w-full text-xl">
            로그인
          </Button>
        </form>
      </Card>
    </div>
  );
}
