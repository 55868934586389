export type PointHistoryType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  point: number;
  type: string;
  adNetwork: string;
  transactionId: string;
  user: { userId: string };
};

export enum BuyType {
  NAVER_PAY = 'NAVER_PAY',
  CULTURE_LAND = 'CULTURE_LAND',
  HAPPY_MONEY = 'HAPPY_MONEY',
  BOOK_AND_LIFE = 'BOOK_AND_LIFE',
}

export const BuyTypeToKor = {
  NAVER_PAY: '네이버 페이 포인트',
  CULTURE_LAND: '컬쳐랜드 상품권',
  HAPPY_MONEY: '해피머니 상품권',
  BOOK_AND_LIFE: '북앤라이프 상품권',
};

export enum BuyStatus {
  PENDING = 'PENDING',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
}

export type BuyHistoryType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  type: BuyType;
  idOrPhoneNumber: string;
  amount: number;
  usedDollar: number;
  status: BuyStatus;
  failureInfo: string | null;
  successInfo: string | null;
  user: { userId: string };
};

export type DollarHistoryType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  dollar: number;
  type: string;
  user: { userId: string };
};

export enum AskHistoryStatus {
  WAIT = 'WAIT',
  FINISH = 'FINISH',
}

export type AskHistoryType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  status: AskHistoryStatus;
  title: string;
  askContent: string;
  replyContent: string;
  isRead: boolean;
  user: { userId: string; nickName: string };
};

export type ApplovinType = {
  time: string;
  network?: string;
  impressions: number;
  estimatedRevenue: number;
  responses: number;
  attempts: number;
  fillRate: number;
  ecpm: number;
};

export type MineType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  upgradeTicket: number;
  storageLevel: number;
  speedLevel: number;
  lastMineTime: string;
  user: { userId: string };
};
