import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Radio,
  Textarea,
} from '@material-tailwind/react';
import { ReactElement, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AskHistoryStatus, AskHistoryType } from '../../types/type';
import { AuthContext } from '../../context';

export default function EditAskHistory({
  askHistory,
  isOpen,
  setIsOpen,
}: {
  askHistory: AskHistoryType;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => Promise<void>;
}): ReactElement {
  const authToken = useContext(AuthContext);

  const [replyContent, setReplyContent] = useState('');
  const [status, setStatus] = useState(AskHistoryStatus.WAIT);

  useEffect(() => {
    setReplyContent(askHistory.replyContent);
    setStatus(askHistory.status);
  }, [askHistory]);

  const saveAskHistory = async (): Promise<void> => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/ask-history/${askHistory.id}`,
        {
          ...askHistory,
          replyContent,
          status,
        },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      )
      .catch(() => {});
  };

  return (
    <>
      <Dialog open={isOpen} handler={setIsOpen}>
        <DialogHeader>{askHistory.title}</DialogHeader>
        <DialogBody divider className="">
          <div className="flex flex-col gap-6">
            <Textarea
              className="p-10"
              label="문의 내용"
              contentEditable={false}
              value={askHistory.askContent}
              onChange={(): void => {}}
            />
            <Textarea
              label="답변 내용"
              resize={true}
              value={replyContent}
              onChange={(e): void => {
                setReplyContent(e.target.value);
              }}
            />
            <div className="flex gap-20">
              <Radio
                labelProps={{ className: 'font-medium text-lg' }}
                name="type"
                label="미 답변"
                id="1"
                defaultChecked
                checked={status === AskHistoryStatus.WAIT}
                onChange={(): void => {
                  setStatus(AskHistoryStatus.WAIT);
                }}
              />
              <Radio
                labelProps={{ className: 'font-medium text-lg' }}
                label="답변 완료"
                id="2"
                checked={status === AskHistoryStatus.FINISH}
                onChange={(): void => {
                  setStatus(AskHistoryStatus.FINISH);
                }}
              />
            </div>
          </div>
        </DialogBody>

        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={async (): Promise<void> => {
              await saveAskHistory();
              setTimeout(() => {
                setIsOpen(false);
              }, 1000);
            }}
            className="mr-1"
          >
            <span>저장</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={(): void => {
              setIsOpen(false);
            }}
          >
            <span>취소</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
