/* eslint-disable import/no-extraneous-dependencies */
import { ReactElement, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Datum, ResponsiveLine } from '@nivo/line';
import { AuthContext } from '../../context';
import { convertDateString } from '../../utils/DateFormat';
import { ChartProps } from '../../types/interface';

export default function DauChart({
  startDate,
  endDate,
}: ChartProps): ReactElement {
  const authToken = useContext(AuthContext);
  const [daus, setDaus] = useState<{ time: string; dau: number }[]>([]);
  const [waus, setWaus] = useState<{ time: string; wau: number }[]>([]);
  const [maus, setMaus] = useState<{ time: string; mau: number }[]>([]);
  const [chartData, setChartData] = useState<
    {
      id: string;
      color: string;
      data: Datum[];
    }[]
  >([]);

  const getDau = async (): Promise<void> => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/visit-history/dau?start=${new Date(
          `${startDate}T00:00:00`
        ).toISOString()}&end=${new Date(`${endDate}T23:59:59`).toISOString()}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      )
      .then((res) => {
        const { data } = res;
        setDaus(data);
      });
  };

  const getWau = async (): Promise<void> => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/visit-history/wau?start=${new Date(
          `${startDate}T00:00:00`
        ).toISOString()}&end=${new Date(`${endDate}T23:59:59`).toISOString()}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      )
      .then((res) => {
        const { data } = res;
        setWaus(data);
      });
  };

  const getMau = async (): Promise<void> => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/visit-history/mau?start=${new Date(
          `${startDate}T00:00:00`
        ).toISOString()}&end=${new Date(`${endDate}T23:59:59`).toISOString()}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      )
      .then((res) => {
        const { data } = res;
        setMaus(data);
      });
  };

  const getData = async (): Promise<void> => {
    await getDau();
    await getWau();
    await getMau();
  };

  useEffect(() => {
    if (startDate === '' || endDate === '') {
      return;
    }
    if (new Date(endDate).getTime() - new Date(startDate).getTime() < 0) {
      return;
    }

    getData();
  }, [startDate, endDate]);

  useEffect(() => {
    const data = [];
    setChartData([]);
    const dauData = daus.map((value) => ({
      x: new Date(value.time.split('T')[0]),
      y: value.dau,
    }));
    const wauData = waus.map((value) => ({
      x: new Date(value.time.split('T')[0]),
      y: value.wau,
    }));
    const mauData = maus.map((value) => ({
      x: new Date(value.time.split('T')[0]),
      y: value.mau,
    }));
    data.push({ id: 'dau', color: '', data: dauData });
    data.push({ id: 'wau', color: '', data: wauData });
    data.push({ id: 'mau', color: '', data: mauData });
    setChartData(data);
  }, [daus, waus, maus]);

  return (
    <ResponsiveLine
      data={chartData}
      margin={{ top: 50, right: 30, bottom: 50, left: 60 }}
      xScale={{
        type: 'time',
        format: '%Y-%m-%d',
        precision: 'day',
      }}
      yScale={{
        type: 'linear',
        min: 0,
        max: 'auto',
        stacked: false,
        reverse: false,
      }}
      yFormat=" >-,.0d"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '날짜',
        legendOffset: 36,
        legendPosition: 'middle',
        format: (value): string => convertDateString(value),
        tickValues: 4,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'DAU / WAU / MAU',
        legendOffset: -40,
        legendPosition: 'middle',
      }}
      enableSlices="x"
      sliceTooltip={({ slice }): ReactElement => (
        <div
          style={{
            background: 'white',
            padding: '9px 12px',
            border: '1px solid #ccc',
          }}
        >
          <strong>
            {convertDateString(new Date(slice.points[0].data.xFormatted))}
          </strong>
          {slice.points.map((point) => (
            <div key={point.id} className="flex flex-row gap-2 py-1">
              <div
                style={{
                  color: point.serieColor,
                }}
              >
                ■
              </div>
              {point.serieId} <strong>{point.data.yFormatted}</strong>
            </div>
          ))}
        </div>
      )}
      pointColor={{ theme: 'background' }}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[]}
    />
  );
}
