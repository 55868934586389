import { ReactElement, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from '@material-tailwind/react';
import { AuthContext, SideBarContext } from '../../context';
import { AllUsers } from '../User';
import { PointHistory, UserPointHistory } from '../Point';
import { SideBar, SideBarMenu } from '../SideBar';
import DollarHistory from '../Dollar/DollarHistory';
import UserDollarHistory from '../Dollar/UserDollarHistory';
import { AskHistory } from '../Ask';
import WaitAskHistory from '../Ask/WaitAskHistory';
import Dau from '../User/Dau';
import Dashboard from '../Dashboard/Dashboard';
import { BuyHistory, WaitBuyHistory } from '../Buy';
import Mine from '../Mine/Mine';

export default function Main(): ReactElement {
  const authToken = useContext(AuthContext);
  const [sideBarMenu, setSideBarMenu] = useState(SideBarMenu.DASHBOARD);
  const navigate = useNavigate();
  useEffect(() => {
    if (authToken === '') {
      navigate('/');
    }
  }, [authToken]);

  const getContent = (): ReactElement => {
    switch (sideBarMenu) {
      case SideBarMenu.DASHBOARD:
        return <Dashboard />;
      case SideBarMenu.ALL_USER:
        return <AllUsers />;
      case SideBarMenu.DAU:
        return <Dau />;
      case SideBarMenu.POINT_HISTORY:
        return <PointHistory />;
      case SideBarMenu.USER_POINT_HISTORY:
        return <UserPointHistory />;
      case SideBarMenu.DOLLAR_HISTORY:
        return <DollarHistory />;
      case SideBarMenu.USER_DOLLAR_HISTORY:
        return <UserDollarHistory />;
      case SideBarMenu.MINE:
        return <Mine />;
      case SideBarMenu.BUY_HISTORY:
        return <BuyHistory />;
      case SideBarMenu.UNCKECKED_BUY_HISTORY:
        return <WaitBuyHistory />;
      case SideBarMenu.ASK_HISTORY:
        return <AskHistory />;
      case SideBarMenu.UNCKECKED_ASK_HISTORY:
        return <WaitAskHistory />;
      default:
        return <></>;
    }
  };
  return (
    <SideBarContext.Provider value={sideBarMenu}>
      <div className="flex h-screen flex-row items-center justify-center space-x-2 bg-blue-gray-100 p-4">
        <SideBar setSideBarMenu={setSideBarMenu} />
        <Card className="h-full w-full p-4 shadow-xl shadow-blue-gray-900/30">
          {getContent()}
        </Card>
      </div>
    </SideBarContext.Provider>
  );
}
