import { ReactElement, useEffect, useState } from 'react';
import { Datum, ResponsiveLine } from '@nivo/line';
import axios from 'axios';
import { Chip } from '@material-tailwind/react';
import { ChartProps } from '../../types/interface';
import { convertDateString } from '../../utils/DateFormat';

export default function TnkChart({
  startDate,
  endDate,
}: ChartProps): ReactElement {
  const [data, setData] = useState<{ time: string; payout: number }[]>([]);
  const [chartData, setChartData] = useState<
    {
      id: string;
      color: string;
      data: Datum[];
    }[]
  >([]);
  const [sum, setSum] = useState(0);

  const getData = async (): Promise<void> => {
    const start = new Date(`${startDate}T00:00:00`);
    const end = new Date(`${endDate}T23:59:59`);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    axios
      .get(
        `https://ad-report-api.moneymoa.net/tnk/daily?start=${start.toISOString()}&end=${end.toISOString()}&application=DollarCash&timezone=${timezone}`
      )
      .then((res) => {
        setData(res.data);
      });
  };

  useEffect(() => {
    if (startDate === '' || endDate === '') {
      return;
    }
    if (new Date(endDate).getTime() - new Date(startDate).getTime() < 0) {
      return;
    }

    getData();
  }, [startDate, endDate]);

  useEffect(() => {
    if (data.length < 0) {
      return;
    }
    const result = [];

    let payoutSum = 0;
    const tnkData = data.map((value) => {
      const payout = Number(value.payout);
      payoutSum += payout;
      const date = new Date(value.time);
      const tz = new Date().getTimezoneOffset() / 60;
      date.setHours(date.getHours() + tz);
      return {
        x: date,
        y: payout,
      };
    });

    setSum(payoutSum);

    result.push({ id: 'TNK', color: '', data: tnkData });

    setChartData(result);
  }, [data]);

  return (
    <div className="flex h-full w-full flex-col">
      <div className="flex items-center justify-center rounded-full">
        <Chip
          value={`총 수익 ₩${sum
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
          size="sm"
          variant="ghost"
          color="blue"
          className="w-fit rounded-full"
        />
      </div>
      <ResponsiveLine
        data={chartData}
        margin={{ top: 20, right: 30, bottom: 50, left: 70 }}
        xScale={{
          type: 'time',
          format: '%Y-%m-%d',
          precision: 'day',
        }}
        yScale={{
          type: 'linear',
          min: 0,
          max: 'auto',
          stacked: false,
          reverse: false,
        }}
        yFormat={' >-,d'}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 36,
          legendPosition: 'middle',
          tickValues: 4,
          format: (value): string => convertDateString(value),
        }}
        axisLeft={{
          format(value): string {
            return `${value}원`;
          },
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '수익',
          legendOffset: -55,
          legendPosition: 'middle',
        }}
        enableSlices="x"
        sliceTooltip={({ slice }): ReactElement => (
          <div
            style={{
              background: 'white',
              padding: '9px 12px',
              border: '1px solid #ccc',
            }}
          >
            <strong>
              {convertDateString(new Date(slice.points[0].data.xFormatted))}
            </strong>
            {slice.points.map((point) => (
              <div key={point.id} className="flex flex-row gap-2 py-1">
                <div
                  style={{
                    color: point.serieColor,
                  }}
                >
                  ■
                </div>
                {point.serieId}{' '}
                <strong>
                  {point.data.yFormatted}
                  {'원'}
                </strong>
              </div>
            ))}
          </div>
        )}
        pointColor={{ theme: 'background' }}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
      />
    </div>
  );
}
