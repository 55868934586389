import { ReactElement, useContext, useEffect, useState } from 'react';
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Chip,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from '@material-tailwind/react';
import {
  PresentationChartBarIcon,
  PowerIcon,
  UserIcon,
  GiftIcon,
  CurrencyDollarIcon,
  ShoppingBagIcon,
  PencilSquareIcon,
  BoltIcon,
} from '@heroicons/react/24/solid';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { AuthContext, SideBarContext } from '../../context';

interface SideBarProps {
  setSideBarMenu: (sideBarMenu: SideBarMenu) => void;
}

export enum SideBarMenu {
  DASHBOARD = 'DASHBOARD',
  ALL_USER = 'ALL_USER',
  DAU = 'DAU',
  POINT_HISTORY = 'POINT_HISTORY',
  USER_POINT_HISTORY = 'USER_POINT_HISTORY',
  DOLLAR_HISTORY = 'DOLLAR_HISTORY',
  USER_DOLLAR_HISTORY = 'USER_DOLLAR_HISTORY',
  MINE = 'MINE',
  BUY_HISTORY = 'BUY_HISTORY',
  UNCKECKED_BUY_HISTORY = 'UNCKECKED_BUY_HISTORY',
  ASK_HISTORY = 'ASK_HISTORY',
  UNCKECKED_ASK_HISTORY = 'UNCKECKED_ASK_HISTORY',
  LOGOUT = 'LOGOUT',
}

export default function SideBar({
  setSideBarMenu,
}: SideBarProps): ReactElement {
  const authToken = useContext(AuthContext);
  const sideBarMenu = useContext(SideBarContext);
  const [open, setOpen] = useState(0);
  const [uncheckedBuyHistoryNum, setUncheckedBuyHistoryNum] = useState(0);
  const [uncheckedAskHistoryNum, setUncheckedAskHistoryNum] = useState(0);

  const getUnreadAskHistryNum = async (): Promise<void> => {
    if (!authToken) return;

    axios
      .get(`${process.env.REACT_APP_API_URL}/ask-history/wait-num`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((res) => {
        setUncheckedAskHistoryNum(res.data.num);
      })
      .catch(() => {
        setUncheckedAskHistoryNum(0);
      });
  };

  const getUnreadBuyHistryNum = async (): Promise<void> => {
    if (!authToken) return;

    axios
      .get(`${process.env.REACT_APP_API_URL}/buy-history/wait-num`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((res) => {
        setUncheckedBuyHistoryNum(res.data.num);
      })
      .catch(() => {
        setUncheckedBuyHistoryNum(0);
      });
  };

  useEffect(() => {
    setSideBarMenu(SideBarMenu.DASHBOARD);
    (async (): Promise<void> => {
      await getUnreadAskHistryNum();
      await getUnreadBuyHistryNum();
    })();

    setUncheckedBuyHistoryNum(0);
  }, [authToken]);

  const handleOpen = (value: number): void => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <Card className="h-full w-full max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/30">
      <div className="mb-2 p-4">
        <Typography variant="h5" color="blue-gray">
          메뉴
        </Typography>
      </div>
      <List>
        <ListItem
          onClick={(): void => {
            setSideBarMenu(SideBarMenu.DASHBOARD);
          }}
        >
          <ListItemPrefix>
            <PresentationChartBarIcon className="h-5 w-5" />
          </ListItemPrefix>
          대시 보드
        </ListItem>
        <Accordion
          open={open === 1}
          icon={
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`mx-auto h-4 w-4 transition-transform ${
                open === 1 ? 'rotate-180' : ''
              }`}
            />
          }
        >
          <ListItem className="p-0" selected={open === 1}>
            <AccordionHeader
              onClick={(): void => {
                handleOpen(1);
                if (
                  sideBarMenu !== SideBarMenu.ALL_USER &&
                  sideBarMenu !== SideBarMenu.DAU
                ) {
                  setSideBarMenu(SideBarMenu.ALL_USER);
                }
              }}
              className="border-b-0 p-3"
            >
              <ListItemPrefix>
                <UserIcon className="h-5 w-5" />
              </ListItemPrefix>
              <Typography color="blue-gray" className="mr-auto font-normal">
                유저
              </Typography>
            </AccordionHeader>
          </ListItem>
          <AccordionBody className="py-1">
            <List className="p-0">
              <ListItem
                onClick={(): void => {
                  setSideBarMenu(SideBarMenu.ALL_USER);
                }}
              >
                <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                전체 계정
              </ListItem>
              <ListItem
                onClick={(): void => {
                  setSideBarMenu(SideBarMenu.DAU);
                }}
              >
                <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                DAU
              </ListItem>
            </List>
          </AccordionBody>
        </Accordion>

        <Accordion
          open={open === 2}
          icon={
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`mx-auto h-4 w-4 transition-transform ${
                open === 2 ? 'rotate-180' : ''
              }`}
            />
          }
        >
          <ListItem className="p-0" selected={open === 2}>
            <AccordionHeader
              onClick={(): void => {
                handleOpen(2);
                if (
                  sideBarMenu !== SideBarMenu.POINT_HISTORY &&
                  sideBarMenu !== SideBarMenu.USER_POINT_HISTORY
                ) {
                  setSideBarMenu(SideBarMenu.POINT_HISTORY);
                }
              }}
              className="border-b-0 p-3"
            >
              <ListItemPrefix>
                <GiftIcon className="h-5 w-5" />
              </ListItemPrefix>
              <Typography color="blue-gray" className="mr-auto font-normal">
                포인트
              </Typography>
            </AccordionHeader>
          </ListItem>
          <AccordionBody className="py-1">
            <List className="p-0">
              <ListItem
                onClick={(): void => {
                  setSideBarMenu(SideBarMenu.POINT_HISTORY);
                }}
              >
                <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                전체 포인트 내역
              </ListItem>
              <ListItem
                onClick={(): void => {
                  setSideBarMenu(SideBarMenu.USER_POINT_HISTORY);
                }}
              >
                <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                계정 포인트 내역
              </ListItem>
            </List>
          </AccordionBody>
        </Accordion>
        <Accordion
          open={open === 3}
          icon={
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`mx-auto h-4 w-4 transition-transform ${
                open === 3 ? 'rotate-180' : ''
              }`}
            />
          }
        >
          <ListItem className="p-0" selected={open === 3}>
            <AccordionHeader
              onClick={(): void => {
                handleOpen(3);
                if (
                  sideBarMenu !== SideBarMenu.DOLLAR_HISTORY &&
                  sideBarMenu !== SideBarMenu.USER_DOLLAR_HISTORY
                ) {
                  setSideBarMenu(SideBarMenu.DOLLAR_HISTORY);
                }
              }}
              className="border-b-0 p-3"
            >
              <ListItemPrefix>
                <CurrencyDollarIcon className="h-5 w-5" />
              </ListItemPrefix>
              <Typography color="blue-gray" className="mr-auto font-normal">
                달러
              </Typography>
            </AccordionHeader>
          </ListItem>
          <AccordionBody className="py-1">
            <List className="p-0">
              <ListItem
                onClick={(): void => {
                  setSideBarMenu(SideBarMenu.DOLLAR_HISTORY);
                }}
              >
                <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                전체 달러 내역
              </ListItem>
              <ListItem
                onClick={(): void => {
                  setSideBarMenu(SideBarMenu.USER_DOLLAR_HISTORY);
                }}
              >
                <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                계정 달러 내역
              </ListItem>
            </List>
          </AccordionBody>
        </Accordion>
        <ListItem
          onClick={(): void => {
            setSideBarMenu(SideBarMenu.MINE);
          }}
        >
          <ListItemPrefix>
            <BoltIcon className="h-5 w-5" />
          </ListItemPrefix>
          채굴소
        </ListItem>
        <Accordion
          open={open === 4}
          icon={
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`mx-auto h-4 w-4 transition-transform ${
                open === 4 ? 'rotate-180' : ''
              }`}
            />
          }
        >
          <ListItem className="p-0" selected={open === 4}>
            <AccordionHeader
              onClick={(): void => {
                handleOpen(4);
                if (
                  sideBarMenu !== SideBarMenu.BUY_HISTORY &&
                  sideBarMenu !== SideBarMenu.UNCKECKED_BUY_HISTORY
                ) {
                  setSideBarMenu(SideBarMenu.BUY_HISTORY);
                }
              }}
              className="border-b-0 p-3"
            >
              <ListItemPrefix>
                <ShoppingBagIcon className="h-5 w-5" />
              </ListItemPrefix>
              <Typography color="blue-gray" className="mr-auto font-normal">
                구매
              </Typography>
              <ListItemSuffix>
                <Chip
                  value={uncheckedBuyHistoryNum}
                  size="sm"
                  variant="ghost"
                  color="blue-gray"
                  className="rounded-full"
                />
              </ListItemSuffix>
            </AccordionHeader>
          </ListItem>
          <AccordionBody className="py-1">
            <List className="p-0">
              <ListItem
                onClick={(): void => {
                  setSideBarMenu(SideBarMenu.BUY_HISTORY);
                }}
              >
                <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                전체 구매 내역
              </ListItem>
              <ListItem
                onClick={(): void => {
                  setSideBarMenu(SideBarMenu.UNCKECKED_BUY_HISTORY);
                }}
              >
                <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                미 확인 구매 내역
                <ListItemSuffix>
                  <Chip
                    value={uncheckedBuyHistoryNum}
                    size="sm"
                    variant="ghost"
                    color="blue-gray"
                    className="rounded-full"
                  />
                </ListItemSuffix>
              </ListItem>
            </List>
          </AccordionBody>
        </Accordion>
        <Accordion
          open={open === 5}
          icon={
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`mx-auto h-4 w-4 transition-transform ${
                open === 5 ? 'rotate-180' : ''
              }`}
            />
          }
        >
          <ListItem className="p-0" selected={open === 5}>
            <AccordionHeader
              onClick={(): void => {
                handleOpen(5);
                if (
                  sideBarMenu !== SideBarMenu.ASK_HISTORY &&
                  sideBarMenu !== SideBarMenu.UNCKECKED_ASK_HISTORY
                ) {
                  setSideBarMenu(SideBarMenu.ASK_HISTORY);
                }
              }}
              className="border-b-0 p-3"
            >
              <ListItemPrefix>
                <PencilSquareIcon className="h-5 w-5" />
              </ListItemPrefix>
              <Typography color="blue-gray" className="mr-auto font-normal">
                문의
              </Typography>
              <ListItemSuffix>
                <Chip
                  value={uncheckedAskHistoryNum}
                  size="sm"
                  variant="ghost"
                  color="blue-gray"
                  className="rounded-full"
                />
              </ListItemSuffix>
            </AccordionHeader>
          </ListItem>
          <AccordionBody className="py-1">
            <List className="p-0">
              <ListItem
                onClick={(): void => {
                  setSideBarMenu(SideBarMenu.ASK_HISTORY);
                }}
              >
                <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                전체 문의 내역
              </ListItem>
              <ListItem
                onClick={(): void => {
                  setSideBarMenu(SideBarMenu.UNCKECKED_ASK_HISTORY);
                }}
              >
                <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                미 확인 문의 내역
                <ListItemSuffix>
                  <Chip
                    value={uncheckedAskHistoryNum}
                    size="sm"
                    variant="ghost"
                    color="blue-gray"
                    className="rounded-full"
                  />
                </ListItemSuffix>
              </ListItem>
            </List>
          </AccordionBody>
        </Accordion>
        <ListItem
          onClick={(): void => {
            setSideBarMenu(SideBarMenu.LOGOUT);
            localStorage.removeItem('auth');
          }}
        >
          <ListItemPrefix>
            <PowerIcon className="h-5 w-5" />
          </ListItemPrefix>
          Log Out
        </ListItem>
      </List>
    </Card>
  );
}
